/* You can add global styles to this file, and also import other style files */
@import 'src/assets/img/theme.scss';

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

.mat-card {
  margin: 2em auto;
  text-align: left;
}

mat-toolbar-row {
  justify-content: space-between;
}

.done {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
}

::ng-deep.mat-tab-label,
::ng-deep.mat-tab-label-active {
  min-width: 0 !important;
  padding: 3px !important;
  margin: 3px !important;
}

.current-app {
  height: 30px;
  font-family: FSSinclair, Roboto, sans-serif;
  font-size: 23px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  display: inline-block;
  margin-left: 15px;
  margin-top: 14px;
}

.example-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.example-header {
  min-height: 64px;
  padding: 8px 24px 0;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}
