@use '@angular/material' as mat;
@include mat.core();
$my-app-primary: mat.define-palette(mat.$orange-palette, 700);
$my-app-accent: mat.define-palette(mat.$pink-palette, 500, 900, A100);
$my-app-warn: mat.define-palette(mat.$deep-orange-palette);
$my-app-theme: mat.define-light-theme(
  $my-app-primary,
  $my-app-accent,
  $my-app-warn
);
@include mat.all-component-themes($my-app-theme);
.alternate-theme {
  $alternate-primary: mat.define-palette(mat.$light-blue-palette);
  $alternate-accent: mat.define-palette(mat.$yellow-palette, 400);
  $alternate-theme: mat.define-light-theme(
    $alternate-primary,
    $alternate-accent
  );
  @include mat.all-component-themes($alternate-theme);
}
.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
  color: white;
}
