
.navbar-brand {
    /* Adjust padding to fit logo size inside header nav */
    padding: 5px 15px !important;
}

.sidebar {
    /* We use a container for top nav, the sidebar do not need a top margin */
    margin-top:0 !important;
}

.intro-message h1,
.intro-message h2,
.intro-message h3,
.intro-message h4,
.intro-message h5,
.intro-message h6 {
    /* White color for introduction container (better contrast) */
    color: #fff;
}

#page-wrapper {
    /* Central page when authenticated */
    padding: 15px 30px;
    background-color: rgba(255, 255, 255, 0.8) !important;
}
